import logo from './logo.svg';
import './App.css';
import React from 'react';
var server = "https://streamedbook.herokuapp.com"

class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      page:"login"
    }
  }

  componentDidMount(){
    var that = this;
    //redirects to appbuilder if sent here from a presentation
    if(window.challenge_mode && (window.Cookies && window.Cookies.get('vaclogin'))){
      if(window.kids_mode){
        window.location = "https://agile-coast-45905.herokuapp.com?kids_mode=true&challenge_mode=" + window.challenge_mode + "&student_name=" + window.Cookies.get('vaclogin');
      } else {
        window.location = "https://agile-coast-45905.herokuapp.com?challenge_mode=" + window.challenge_mode + "&student_name=" + window.Cookies.get('vaclogin');
      }
      
    }



    if(window.my_newspapers && (window.Cookies && window.Cookies.get('vaclogin'))){
      window.location = "https://vacclassdashboard.herokuapp.com/my_newspapers?username=" + window.Cookies.get('vaclogin');
    }

    if(window.my_businesses && (window.Cookies && window.Cookies.get('vaclogin'))){
      window.location = "https://vacclassdashboard.herokuapp.com/my_businesses?username=" + window.Cookies.get('vaclogin');
    }

    if(window.my_portfolios && (window.Cookies && window.Cookies.get('vaclogin'))){
      window.location = "https://vacclassdashboard.herokuapp.com/my_portfolios?username=" + window.Cookies.get('vaclogin');
    }

    https://www.vaclearn.com/?challenge_mode=YourFirstApp

    try{

    if(window.challenge_mode_ai && (window.Cookies && window.Cookies.get('vaclogin'))){
      window.location = "https://mighty-harbor-08447.herokuapp.com?challenge_mode=" + window.challenge_mode_ai + "&student_name=" + window.Cookies.get('vaclogin');
    }

    if(window.chatbot_mode && (window.Cookies && window.Cookies.get('vaclogin'))){
      window.location = "https://www.vacenrichment.com/chatbot?id=" + window.Cookies.get('vaclogin') +"_" +window.chatbot_mode
    }
  } catch(e){
    console.log(e)
  }
   

    if(window.Cookies.get('vaclogin')){
        var username = window.Cookies.get('vaclogin');
        window.username =username
        window.student_name = username;
    } 

    
     

    if(window.student_name && window.student_name !== "undefined" && window.student_name !== undefined){
      if(window.student_name && window.student_name.indexOf("xzyva") !== -1){
          window.student_name = window.student_name.replace("xzyva","").slice(0, -5); 
        }
      console.log(window.student_name)
      console.log(window.server + "/summer_login?username=" + window.student_name)
      window.axios.get(window.server + "/summer_login?username=" + window.student_name).then(function(response){
        response = response.data;
        console.log(response);

        if(response.status === "Success" && response.class_descriptions && response.user){
          //load class descriptions

           if(window.Cookies){
                console.log("LOGGED IN")
                console.log("Setting cookies")
                console.log(window.student_name);
              window.Cookies.set('vaclogin',window.student_name);
              
            }
          
           try{
              // console.log(window.appFrame.state.class_descriptions);
              var descriptions = response.class_descriptions;
              var custom_curricula = response.custom_curricula;
              var extra_lessons = response.extra_lessons;
              var extra_lessons_obj = []
              var added = {}
              extra_lessons.forEach(function(a){
                var key = a.school_name + " " + a.curriculum_name
                if(!added[key]){
                  added[key] = true
                  extra_lessons_obj.push({school_name:a.school_name,
                  curriculum_name: a.curriculum_name})
                }
                
              })

              // var extra_lessons_obj = {}
              // console.log("GETTING CUSTOM CURRICULUA")
              // console.log(custom_curricula)
              var list = descriptions.filter(a=> a.name === window.summer);
              if(list.length > 0 && list[0].curriculum_link){
                  window.summer_link = list[0].curriculum_link
              } else {
                window.summer_link = "https://docs.google.com/presentation/d/e/2PACX-1vSYUDKvqcO34WF4Udbo_HynZGO1MaBNFH5gm_cvR16YUuBuIYtr7Q0mre_3_O3cAoMvkeZvXL5yPDOq/embed?start=false&loop=false&delayms=60000000&rm=minimal"
                window.$("#class_select").val("Coding");
              }
              
            } catch(e){
              console.log(e)
              window.summer_link = "https://docs.google.com/presentation/d/e/2PACX-1vSYUDKvqcO34WF4Udbo_HynZGO1MaBNFH5gm_cvR16YUuBuIYtr7Q0mre_3_O3cAoMvkeZvXL5yPDOq/embed?start=false&loop=false&delayms=60000000&rm=minimal"
              window.$("#class_select").val("Coding");
            }

          //load user
          var user = response.user; 
          console.log(user);
          console.log(window.custom_upload);
          console.log(window.student_name)

          if(window.custom_upload && (window.Cookies && window.student_name && !user.vac_class_id)){
            alert("We don't have your class! Please select a class to upload a project")

          }

          if(window.custom_upload && (window.Cookies && window.student_name && user.vac_class_id)){
              if(window.custom_upload === "image"){
                // alert()
                window.location = "https://streamedbook.herokuapp.com/custom_upload_form?class_id="+user.vac_class_id+"&student_name=" + window.student_name
              } else if(window.custom_upload === "text"){
                window.location = "https://streamedbook.herokuapp.com/upload_text?class_id="+user.vac_class_id+"&student_name=" + window.student_name
              }
             
          }

          if(user.class_id){
            window.Cookies.set('vac_class',user.class_id);
          }



          if(window.Cookies.get('vac_class_topic')){

            window.$(document).ready(function() { 

               // console.log("LOADING CLASS")
                var saved = window.Cookies.get('vac_class_topic');
                // console.log(saved)
                try{
                  window.chooseTopic(saved)
                }catch(e){
                  console.log(e)
                }



            });
           
            
          }


          var obj = user;
          
          //check if user has class_id

          try{
            console.log("CLASS ID:")
            console.log(obj.vac_class_id)

             window.axios.get('https://vacclassdashboard.herokuapp.com/current_classes').then(function(response){

              if(response.data.status === "Success"){

                if(response.data.data && typeof response.data.data === "string"){
                  response.data.data = JSON.parse(response.data.data)
                }
                console.log(response.data)
                if(response.data.data && (!obj.vac_class_id || response.data.data.filter(a => a.class_id === obj.vac_class_id).length === 0)){
                  
                  var classes = response.data.data;
                  var schools = {};
                  classes.forEach(function(vac_class){
                    if(!schools[vac_class.school_name]){
                      schools[vac_class.school_name] = [vac_class]
                    } else {
                      schools[vac_class.school_name].push(vac_class)
                    }
                  
                  })

                  window.vac_schools = schools;
                  window.vac_classes = classes;

                  window.submit_vac_class = function(){
                    var id = window.$('#vac_class_select').val()
                    console.log('https://vacclassdashboard.herokuapp.com/set_user_id?vac_class_id=' + id + "&phone_id=" + obj.phone_id);

                    if(isNaN(parseInt(id))) return alert("Please select a class!");
                    window.axios.get('https://vacclassdashboard.herokuapp.com/set_user_id?vac_class_id=' + id + "&phone_id=" + obj.phone_id).then(function(response){
                      if(response.data.status && response.data.status === "Success"){
                        console.log(response.data)
                        alert("Thanks!")
                        window.Cookies.set('vac_class',id);
                         window.$("#vacclasschoice").dialog('close')

                      } else {
                     
                          alert("There was an issue! We'll try again the next time you login")
                          window.$("#vacclasschoice").dialog('close')
                      }
                    }).catch(function(e){
                      console.log(e)
                      alert("There was an issue! We'll try again the next time you login")
                      window.$("#vacclasschoice").dialog('close')
                    })

                  }

                  window.change_vac_class_select = function(school_name){
                    console.log(school_name)
                    var options = window.vac_classes.filter(a => a.school_name === school_name).map(function(a){
                      return `<option value = "`+a.class_id+`">`+a.topic+ ` For Grades `+a.grade_range+`, `+ window.militaryToStandard(a.start_time) +`</option>`
                    }).join("")
                    console.log(options);
                    window.$('#vac_class_select').empty().append(options)
                  }

                   window.$("#vacclasschoice").empty().append(window.$(`

                   <h1 style = "  text-align: center; color:#10162F">
                   <span style = "">
                   We don't have your class! Please select a class. This will allow you to login easier in the future:
                   </span>
                   </h1>
                   <br/>
            
                  <div>
                    <div style = "display:grid; grid-template-columns: 1fr 1fr">
                        <div>
                          <p style = "text-align:center;color:black; font-size:1.3em; text-decoration-line:underline">
                            Select your school
                          </p>
                        </div>
                        <div>

                          <select style = "width:100%;background:#354051; font-size:1.1em;color:white" id = "vac_school_select" onchange = "window.change_vac_class_select($('#vac_school_select').val())">
                            <option value = "">Select </option>
                            `+  Object.keys(schools).sort().map(a => `<option value = "`+a.trim()+`">`+a.trim()+`</option>`).join("") + `
                          </select>
                        </div>
                      </div>
                      <div style = "display:grid; grid-template-columns: 1fr 1fr">
                        <div>
                          <p style = "text-align:center;color:black; font-size:1.3em; text-decoration-line:underline">
                           Then select your class
                          </p>
                        </div>
                        <div>
                          <select style = "width:100%;font-size:1.1em;background:#354051;color:white" id = "vac_class_select">
                            <option value = "">Select </option>
                          </select>
                        </div>
                      </div>
                    <br/>
                    <br/>
                    <button style = "background-color: #354051 !important;color:white !important; width:100%; font-size:1.5em; border-radius:10px" onclick = "window.submit_vac_class()">Submit</button>
                  </div>

                  `))

                  window.vacclasschoice.dialog('open')

                }




              } else {
                console.log(response.data)
              } 

             }).catch(function(e){
              console.log(e)
             })
            
          }catch(e){
            console.log(e)
          }





            that.setState({page:"home",extra_lessons_obj,class_descriptions:descriptions, custom_curricula:custom_curricula, summer_link:window.summer_link, user:user})


        } else {
          alert("There was an issue logging in. Email admin@vineyardappcamp.com");


        }

          
        

      }).catch(function(e){
        console.log(e);
      })

      that.setState({
        page:"home"
      })
    }
  }

  render(){
    var that = this;
    if(that.state.page === "login"){


      return (
        <div id = "login_main_div">


      <img class = "backgroundGif" src = "/tile_background.png"></img>
      <img onclick = "window.trainingTourStart()" 
      id = "topphone" class = "rotate"
       src  = "/whitephone.png" 
       style = {{position:"absolute",
       height:"80%",top:"10%", width:"40%",
       left:"-3%"}}></img>
      <img src  = "https://static.wixstatic.com/media/c5a71b_0a694cd17bd04d8fbcde1e24e5b30f12~mv2.png/v1/fill/w_882,h_632,al_c,q_90,usm_0.66_1.00_0.01/VINEYARD%20BANNER%202.webp" style = {{position:"absolute", maxWidth:"30%", width:"auto", resizeMode:"contain", top:"5%",left:"70%"}}></img>
      <img src  = "https://static.wixstatic.com/media/c5a71b_2f3e693dd9634b7e9ed6e2d48803210e~mv2.png/v1/fill/w_1010,h_720,al_c,q_90,usm_0.66_1.00_0.01/vineyard%20kid%20.webp" style = {{position:"absolute", maxWidth:"30%", height:"auto", maxHeight:"40%", width:"auto", resizeMode:"contain", top:"45%",left:"70%"}}></img>
      <div style = {{width:"60%"}}  class = "loginBox">

         <h1 style = {{fontFamily:"Lato", fontSize:"36px", textDecorationLine:"underline", color:"white"}} class="important_header">👋 VAC: Let's get learning in afterschool & summer camp!</h1>

      

         <div id = "login_button_row"  style = {{display:"grid",gridTemplateColumns:"1fr 1fr 1fr", marginTop:"5%", textAlign:"center"}}>
          <button class = "button-1" style={{
           backgroundColor:"green", borderRadius:5,
           height:"70%",
           width:"100%",
          color: 'white',fontSize: "0.7em",
          padding:0 }} onClick={function () {
            window.$("#login_real").css('display','block');
            //window.login_all_kids() 

            
              // var first_name = prompt('What is your first name?')
              // var last_name = prompt('What is your last name?')
              // if(!first_name || !last_name || first_name.length<2 || last_name.length<2){
              //   return alert("Please enter a name")
              // }
              // window.location = "https://vacplatformdev.herokuapp.com?student_name=" + (first_name + "_" + last_name) 




            

          }}> Login</button>

          <button class = "button-1" style={{
           backgroundColor:"green", borderRadius:5, 
           fontSize: "0.7em",
          padding:0 ,
           height:"70%",
           width:"100%",
          color: 'white'}} onClick={function () {
          window.$("#create_account_real").css('display','block');
         //window.open("https://streamedbook.herokuapp.com/create_2")

          }}> Create an account</button>

          
          
        

           <button class = "button-1" style={{
           backgroundColor:"green", borderRadius:5, 
           height:"70%",
           width:"100%",
          color: 'white',   fontSize: "0.7em",
          padding:0  }} onClick = {function(){
            window.open('https://tinyurl.com/vacgame/backup_login')
           }}> 
           Having trouble logging in? Click here</button>

           <button class = "button-1" style={{
           backgroundColor:"green", borderRadius:5, 
           height:"70%",
           width:"100%",
          color: 'white',   fontSize: "0.7em",
          padding:0  }} onClick = {function(){
            window.open('https://agile-coast-45905.herokuapp.com/?littlekidsmode=true')
           }}> 
           Start Little Learners Project <br/> (K-1 Learners Only!)</button>

           <button class = "button-1" style={{
           backgroundColor:"green", borderRadius:5, 
           height:"70%",
           width:"100%",
          color: 'white',   fontSize: "0.7em",
          padding:0  }} onClick = {function(){
            window.open('https://agile-coast-45905.herokuapp.com/?littlekidsmode=true&app_name=' + prompt('Enter the app code'))
           }}> 
           Resume Little Learners Project</button>

          <div style = {{justifyContent:"center",fontSize:"0.6em", marginTop:"5%", alignItems:"center",textAlign:"center"}}>
            <p onClick = {function(){
              window.open('https://streamedbook.herokuapp.com/vac_privacy')
            }}>
            
            Privacy Policy/Media Rights
            </p>
           </div>
           <div style = {{justifyContent:"center",marginTop:"5%",fontSize:"0.6em", alignItems:"center",textAlign:"center"}}>
            <p onClick = {function(){
              window.open('https://streamedbook.herokuapp.com/vac_tos')
            }}>
            Terms of Service
            </p>
           </div>
          
         
        </div>

      </div>
    </div>

        )
    } else if (that.state.page === "home"){
      return (
      
      <div>
             <div id="topbar"  style={{backgroundColor:"#4E97FF", display:"grid",gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr 1fr", position: "absolute", alignItems:'center', top: "0%", left: "0%", height:"6%",  width: "100%", zIndex: 100 }}>
 

         
         
            <img onClick ={ function(){
         window.app.setState({
                page: "default",
                answers: [],
                teacher_question: "",
                address: ""
              });
         } } id = "bulbpic" src="/bulb.png" style={{ height:"38px", marginLeft:"5%", padding: "2px", alignSelf:"center", marginRight:"6%", position:"relative", zIndex: "999999999"}}></img>
       


     
       

        
          <button className = {"homeNavButtonStyle"}   onClick ={ function(){ window.account_page.dialog('open');}}  >Account</button>
         <button className = {"homeNavButtonStyle"} onClick = {function(){
               window.open("http://vacclassdashboard.herokuapp.com/student_feedback");
            }}>Survey!</button>
              <button className = {"homeNavButtonStyle"}  onClick = {function(){
               window.image_editor.dialog('open')
            }}>Images</button>
           
            
            {that.state.class_descriptions ?
           ( <select 
           value = "Coding"

           onChange = {function(){
            var val = window.$("#class_select").val();
            console.log(val);
            window.Cookies.set('vac_class_topic', window.$( "#class_select option:selected" ).text());
            window.$("#content").attr('src',val);
           }} style = {{ fontSize:"0.9vw", backgroundColor:"white", color:"black"}} id = {"class_select"}  >
           <option>Select A Class</option>
          {that.state.class_descriptions.sort(function(a,b){
            console.log("sorting")
            var textA = a.name.trim().toLowerCase();
            var textB = b.name.trim().toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

          }).map(function(a){
            if(a.backup_curriculum_url){
              return (<optgroup label="Game Design"><option style ={{color:"black"}} value = {a.curriculum_link ? a.curriculum_link.trim():"https://docs.google.com/presentation/d/e/2PACX-1vSYUDKvqcO34WF4Udbo_HynZGO1MaBNFH5gm_cvR16YUuBuIYtr7Q0mre_3_O3cAoMvkeZvXL5yPDOq/embed?start=false&loop=false&delayms=60000000&rm=minimal".trim()}>{a.name.trim()} Usual</option><option style ={{color:"black"}} value = {a.backup_curriculum_url}>Backup {a.name.trim()}</option> </optgroup>)
            }

           return (<option style ={{color:"black"}} value = {a.curriculum_link ? a.curriculum_link.trim():"https://docs.google.com/presentation/d/e/2PACX-1vSYUDKvqcO34WF4Udbo_HynZGO1MaBNFH5gm_cvR16YUuBuIYtr7Q0mre_3_O3cAoMvkeZvXL5yPDOq/embed?start=false&loop=false&delayms=60000000&rm=minimal".trim()}>{a.name.trim()}</option>)
          }) }

          { that.state.extra_lessons_obj ?  (<optgroup  label="Extra Curriculum Lessons">{that.state.extra_lessons_obj.map(function(a, i){
            var {school_name, curriculum_name} = a
           
            return (<option key={i} style ={{color:"black"}} value = {"https://www.vacenrichment.com/render-lessons-student?y=" + school_name + "&curriculum_name=" +  curriculum_name}>{school_name + " " + curriculum_name}</option>)
          })} </optgroup>): null

          }

          { (Array.isArray(that.state.custom_curricula) && that.state.custom_curricula.length > 0) ?  (<optgroup  label="School Specific Curriculums">{that.state.custom_curricula.sort(function(a,b){
            console.log("sorting")
            var textA = a.school_name.trim().toLowerCase();
            var textB = b.school_name.trim().toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

          }).map(function(a){
            var {school_name, class_topic, class_description_id,new_curricula_url} = a
            var template = "https://drive.google.com/file/d/"+new_curricula_url.split("/")[5]+"/preview"
            return (<option style ={{color:"black"}} value = {template ? template:"https://docs.google.com/presentation/d/e/2PACX-1vSYUDKvqcO34WF4Udbo_HynZGO1MaBNFH5gm_cvR16YUuBuIYtr7Q0mre_3_O3cAoMvkeZvXL5yPDOq/embed?start=false&loop=false&delayms=60000000&rm=minimal".trim()}>{school_name.trim() + " " + class_topic.trim()}</option>)
          })} </optgroup>): null

          }
          </select>):null
        }
           


  

       
         

       
         
          
         
         
        </div>
      
             <div class="google-slides-container">
                <iframe id = "content"  allowFullScreen={true}  frameBorder="0" loop = {false} width="100%" height="100%" src = {that.state.summer_link}>

                </iframe>
              </div>
      </div>

      )
    }
  }
}

export default App;
